// noinspection JSUnresolvedVariable

import Vue from 'vue';
// import store from "./store";

import * as qs from 'qs';
import axios from 'axios';
import {sprintf, vsprintf} from 'sprintf-js';

// noinspection SpellCheckingInspection
Vue.mixin({
  data() {
    return {
      errorMessage: {
        required: 'This field is required',
        minLength: 'This field requires at least %d characters',
        email: 'Invalid email address',
        url: 'Invalid url address',
      }
    };
  },
  methods: {
    sprintf: sprintf,
    vsprintf: vsprintf,
    getErrorMessage(errors) {
      if (errors.$error) {
        let type = false;

        Object.entries(errors).find(([key, value]) => {
          // console.log(key);
          if (!key.startsWith('$') && !value && !type) {
            type = key;
          }
        });
        let message = this.errorMessage[type];

        switch (type) {
          case 'minLength':
            return this.sprintf(message, errors.$params.minLength.min)
          default:
            return message;
        }
      }
    },
    sendRequest(request) {
      // noinspection JSUnresolvedVariable
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

      let def = {
        method: 'post',
        url: '',
        data: {}
      };

      let options = Object.assign(def, request);
      if (request.data && options.method !== 'get') {
        request.data = qs.stringify(request.data);
      }

      return axios(options);
    },
    sortByField(property) {
      let sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }

      return function (a, b) {
        let aVal = typeof a[property] === 'string' ? a[property].toLowerCase() : a[property];
        let bVal = typeof b[property] === 'string' ? b[property].toLowerCase() : b[property];

        let result = (aVal < bVal) ? -1 : (aVal > bVal) ? 1 : 0;
        return result * sortOrder;
      }
    },
    strToCase(text, type = 'camel') {
      if (text) {
        text = text.toString();
        let words = text.split(/[-_\s\n]/g).map(item => item.toLowerCase());
        let newWords = [];

        words.forEach((item, index) => {
          if (type === 'camel' && index) {
            item = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
          } else if (type === 'pascal') {
            item = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
          }

          newWords.push(item);
        });

        let divider;
        switch (type) {
          case 'snake':
            divider = '_';
            break;
          case 'kebab':
            divider = '-';
            break;
          default:
            divider = '';
            break;
        }

        return newWords.join(divider);
      } else {
        return text;
      }
    },
    getTableColumn(item) {
      let title = item.replace(/_id$/, '');

      return {
        text: this.$filters.caseToTitle(title),
        value: item,
        sortable: item !== 'id',
        class: item !== 'id' ? 'table-header' : '',
      }
    },
    createCopyArray(arr, sort = false) {
      let items = [];

      if (Array.isArray(arr)) {
        arr.forEach(item => {
          items.push(Object.assign({}, item));
        });
      }

      if (sort) {
        items.sort(this.sortByField(sort));
      }

      return items;
    },
    createCopyObject(obj) {
      let newObj = {};

      Object.entries(obj).forEach(([key, value]) => {
        newObj[key] = value;
      });

      return Object.assign({}, newObj);
    },
    toTextValue(arr, keys = []) {
      let pairs = [];
      if (Array.isArray(arr)) {
        arr.forEach(item => {
          if (typeof item === 'object') {
            if (keys.length > 1) {
              pairs.push({
                text: item[keys[0]],
                value: item[keys[1]],
              });
            } else if (item.text && item.value) {
              pairs.push(item);
            }
          } else {
            pairs.push({
              text: this.$filters.caseToTitle(item),
              value: item,
            });
          }
        });
      } else if (typeof arr === 'object') {
        if (keys.length > 1) {
          return {
            text: arr[keys[0]],
            value: arr[keys[1]],
          };
        }
      }

      return pairs;
    }
  },
  filters: {
    capitalize(value) {
      if (!value) {
        return '';

      }
      value = value.toString();

      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    ucFirst(text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
    caseToTitle(text) {
      text = text.replace(/[-_]/g, ' ');

      return text.charAt(0).toUpperCase() + text.slice(1);
    },
  },
});

Vue.prototype.$filters = Vue.options.filters;
