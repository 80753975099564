var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup__wrapper" },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.show
          ? _c(
              "div",
              {
                staticClass: "popup__overlay",
                on: {
                  click: function ($event) {
                    if ($event.target !== $event.currentTarget) return null
                    return _vm.toggle.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "popup popup-form",
                    class: { "popup-success": _vm.success },
                  },
                  [
                    _c("div", {
                      staticClass: "popup__close",
                      on: { click: _vm.toggle },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "popup__content" },
                      [
                        _c("contact-form", {
                          attrs: {
                            "filled-button": "",
                            title: _vm.options.popup.form_title,
                            "hide-text": "",
                          },
                          on: { success: _vm.onSuccess },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }