<template>
  <div class="contact-form">
    <transition name="fade" mode="out-in">
      <div v-if="!success" key="form" class="contact-form__wrapper">
        <div class="contact-form__title h3" v-if="formTitle">{{ formTitle }}</div>
        <div class="contact-form__text" v-if="formText && !hideText">{{ formText }}</div>

        <form class="form">
          <div class="form-field">
            <span class="form-error" v-if="$v.form.name.$error">{{ getErrorMessage($v.form.name) }}</span>
            <input type="text"
                   :class="{
                      filled: form.name.length,
                      error: $v.form.name.$error,
                   }"
                   v-model="form.name"
                   :placeholder="options.form.name" />
          </div>
          <div class="form-field">
            <span class="form-error" v-if="$v.form.email.$error">{{ getErrorMessage($v.form.email) }}</span>
            <input type="email"
                   :class="{
                      filled: form.email.length,
                      error: $v.form.email.$error,
                   }"
                   v-model="form.email"
                   :placeholder="options.form.email" />
          </div>
          <div class="form-field">
            <span class="form-error" v-if="$v.form.phone.$error">{{ getErrorMessage($v.form.phone) }}</span>
            <input type="tel"
                   :class="{
                      filled: form.phone.length,
                      error: $v.form.phone.$error,
                   }"
                   v-model="form.phone"
                   :placeholder="options.form.phone" />
          </div>

          <div>
            <button type="submit"
                    @click="submit"
                    :class="{ 'btn-transparent': !filledButton }"
                    class="btn btn-rounded"><span>{{ formButton }}</span></button>
          </div>
        </form>
      </div>

      <div v-if="success" key="thanks" class="thank-you">
        <!--suppress JSUnresolvedVariable -->
        <div class="thank-you__title h1">{{ options.form.thank_title }}</div>
        <!--suppress JSUnresolvedVariable -->
        <div class="thank-you__text h3">{{ options.form.thank_text }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import {minLength, required, email} from "vuelidate/lib/validators";

/**
 * @property {object} $v
 */
export default {
  name: "contactForm",
  props: {
    title: String,
    text: String,
    button: String,
    filledButton: Boolean,
    hideText: Boolean,
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
      },
      loading: false,
      success: false,
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(4)
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      }
    },
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    formTitle() {
      return this.title || this.options.form.title;
    },
    formText() {
      return this.text || this.options.form.text;
    },
    formButton() {
      return this.button || this.options.form.button;
    }
  },
  methods: {
    submit(event) {
      event.preventDefault();

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sendForm();
      }
      // this.success = true;
      // this.$emit('success');
    },
    sendForm() {
      let data = {
        action: 'sendCallback',
        form: this.form,
      };

      if (!this.loading) {
        this.loading = true;
        return this.$store.dispatch('sendRequest', data).then(res => {
          // console.log(res);
          this.loading = false;

          if (res.data.success && res.data.data.result) {
            this.success = true;

            this.$emit('success');
          }
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-form {
  max-width: 400px;
  text-align: center;


  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    margin-bottom: 30px;
  }

  &__text {
    margin-bottom: 30px;
  }

  button {
    &.btn-transparent {
      span {
        background: #101218;
        position: relative;
        //background: rgba(#F38606, 0.15);

        &:before {
          content: '';
          display: block;
          border-radius: 100px;
          transition: all 0.4s;

          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          background: rgba(#F38606, 0.15);
        }
      }

      &:hover {
        span {
          background: #F38606;

          &:before {
            background: rgba(#F38606, 0);
          }
        }
      }
    }
  }

  .thank-you {
    &__title {
      margin-bottom: 20px;
    }
  }
}
</style>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
