<!--suppress JSUnresolvedVariable -->
<template>
  <div class="popup__wrapper">
    <transition name="fade" mode="out-in">
      <div class="popup__overlay" @click.self="toggle" v-if="show">
        <div class="popup">
          <div class="container">
            <div class="popup__container">
              <div class="popup__close" @click="toggle"></div>

              <div class="popup__content">
                <div class="popup__title h3">{{ options.cookies.title }}</div>
                <div class="popup__text" v-html="options.cookies.text"></div>
              </div>

              <div class="popup__buttons">
                <button class="btn-link" @click="accept">{{ 'Accept' }}</button>

<!--                <button class="btn-link" @click="toggle">{{ 'Decline' }}</button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "popupCookies",
  data() {
    return {
      show: false,
      delay: 3000,
      // show: !this.$cookies.get('CookieConsent'),
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
  },
  mounted() {
    let buttons = document.querySelectorAll('[data-popup=cookies]');
    buttons.forEach(button => {
      button.addEventListener('click', () => this.toggle());
    });

    let show = !this.$cookies.get('CookieConsent');
    if (show) {
      setTimeout(() => {
        this.show = true;
      }, this.delay);
    }
  },
  methods: {
    toggle() {
      this.show = !this.show;
      document.body.classList.toggle('over-hidden');
    },
    accept() {
      this.$cookies.set('CookieConsent', true);

      this.toggle();
    },
  },
}
</script>

<style lang="scss" scoped>
.popup__overlay {
  align-items: flex-end;
}
</style>
