var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-form" },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        !_vm.success
          ? _c("div", { key: "form", staticClass: "contact-form__wrapper" }, [
              _vm.formTitle
                ? _c("div", { staticClass: "contact-form__title h3" }, [
                    _vm._v(_vm._s(_vm.formTitle)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.formText && !_vm.hideText
                ? _c("div", { staticClass: "contact-form__text" }, [
                    _vm._v(_vm._s(_vm.formText)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("form", { staticClass: "form" }, [
                _c("div", { staticClass: "form-field" }, [
                  _vm.$v.form.name.$error
                    ? _c("span", { staticClass: "form-error" }, [
                        _vm._v(_vm._s(_vm.getErrorMessage(_vm.$v.form.name))),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.name,
                        expression: "form.name",
                      },
                    ],
                    class: {
                      filled: _vm.form.name.length,
                      error: _vm.$v.form.name.$error,
                    },
                    attrs: { type: "text", placeholder: _vm.options.form.name },
                    domProps: { value: _vm.form.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _vm.$v.form.email.$error
                    ? _c("span", { staticClass: "form-error" }, [
                        _vm._v(_vm._s(_vm.getErrorMessage(_vm.$v.form.email))),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.email,
                        expression: "form.email",
                      },
                    ],
                    class: {
                      filled: _vm.form.email.length,
                      error: _vm.$v.form.email.$error,
                    },
                    attrs: {
                      type: "email",
                      placeholder: _vm.options.form.email,
                    },
                    domProps: { value: _vm.form.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "email", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field" }, [
                  _vm.$v.form.phone.$error
                    ? _c("span", { staticClass: "form-error" }, [
                        _vm._v(_vm._s(_vm.getErrorMessage(_vm.$v.form.phone))),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.phone,
                        expression: "form.phone",
                      },
                    ],
                    class: {
                      filled: _vm.form.phone.length,
                      error: _vm.$v.form.phone.$error,
                    },
                    attrs: { type: "tel", placeholder: _vm.options.form.phone },
                    domProps: { value: _vm.form.phone },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "phone", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-rounded",
                      class: { "btn-transparent": !_vm.filledButton },
                      attrs: { type: "submit" },
                      on: { click: _vm.submit },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.formButton))])]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.success
          ? _c("div", { key: "thanks", staticClass: "thank-you" }, [
              _c("div", { staticClass: "thank-you__title h1" }, [
                _vm._v(_vm._s(_vm.options.form.thank_title)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "thank-you__text h3" }, [
                _vm._v(_vm._s(_vm.options.form.thank_text)),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }