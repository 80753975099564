import Vue from 'vue';
import store from './store/store';
import './store/mixin';


// import Vuelidate from 'vuelidate';
// Vue.use(Vuelidate);
import { validationMixin } from 'vuelidate';
Vue.mixin(validationMixin);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);


import contactForm from "./components/contactForm";
import popupForm from "./components/popupForm";
import popupCookies from "./components/popupCookies";

let wrapper = document.querySelector('[data-vue=contact-form]');
if (wrapper) {
  // let container = document.createElement('div');
  // wrapper.append(container);

  new Vue({
    el: wrapper,
    render: h => h(contactForm),
    store,
    // router,
  });
}


let formContainer = document.createElement('div');
document.body.append(formContainer);
new Vue({
  el: formContainer,
  render: h => h(popupForm),
  store,
  // router,
});


let cookiesContainer = document.createElement('div');
document.body.append(cookiesContainer);
new Vue({
  el: cookiesContainer,
  render: h => h(popupCookies),
  store,
  // router,
});
