<!--suppress JSUnresolvedVariable -->
<template>
  <div class="popup__wrapper">
    <transition name="fade" mode="out-in">
      <div class="popup__overlay" @click.self="toggle" v-if="show">
        <div class="popup popup-form" :class="{'popup-success': success}">
          <div class="popup__close" @click="toggle"></div>

          <div class="popup__content">
            <contact-form filled-button
                          v-on:success="onSuccess"
                          :title="options.popup.form_title"
                          hide-text></contact-form>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ContactForm from "./contactForm";

export default {
  name: "popupForm",
  components: {ContactForm},
  data() {
    return {
      show: false,
      success: false,
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
  },
  mounted() {
    let buttons = document.querySelectorAll('[data-popup=contact]');

    buttons.forEach(button => {
      button.addEventListener('click', () => this.toggle());
    });
  },
  methods: {
    toggle() {
      this.show = !this.show;
      this.success = false;

      document.body.classList.toggle('over-hidden');
    },
    onSuccess() {
      this.success = true;

      setTimeout(this.toggle, 2000);
    }
  },
}
</script>

<style lang="scss" scoped>
.popup {
  max-width: 500px;
  min-height: 550px;
}
</style>
